@tailwind utilities;
@tailwind components;
@import './fonts.css';

:root {
  --outside-header-height: 60px;
  --site-header-height: 76px;
  --site-header-height-mobile: 52px;

  --outside-footer-height: 518px;
}

@media (min-width: 767px) {
  :root {
    --outside-header-height: 68px;

    --outside-footer-height: 661px;
  }
}

html {
  scrollbar-gutter: stable;
}

main {
  min-height: calc(
    100vh - var(--outside-header-height) - var(--site-header-height)
  );
}

/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

/*------------------------------------*\
 #BUTTON COLORS
\*------------------------------------*/
.btn-primary {
  @apply box-border border border-transparent bg-network-outside-yellow text-network-black shadow-theme-shadow-default;
}

.btn-primary:hover {
  @apply box-border border-transparent bg-network-gold-light text-network-black shadow-theme-shadow-none;
}

.btn-secondary {
  @apply border border-transparent bg-network-black text-network-white shadow-theme-shadow-default;
}

.btn-secondary:hover {
  @apply border-transparent bg-network-gold text-network-white shadow-theme-shadow-none;
}

.btn-secondary-outline {
  @apply box-border border-2 border-network-black bg-transparent text-network-black shadow-theme-shadow-default;
}

.btn-secondary-outline:hover {
  @apply border-network-black bg-network-black text-network-white shadow-theme-shadow-none;
}

/* WordPress default Image styling  */
.aligncenter,
.alignleft,
.alignright {
  display: block;
  padding: 0;
}

.aligncenter {
  float: none;
  margin: 0.5em auto 1em;
}

.alignright {
  float: right;
  margin: 0.5em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 0.5em 1em 1em 0;
}

/* brings the video player play button above the overlay */
.jw-display {
  z-index: 1;
}

/* this brings the play/pause, mute/volume control buttons above the overlay on the video player */
.jw-icon,
.jw-controlbar {
  z-index: 1;
}

/* TODO - use postcss-import to move these shared JWPlayer styles into a shared stylesheet */

/* JW Player */
.preview-video .jw-slider-time,
.preview-video .jw-button-container .jw-icon-playback,
.preview-video .jw-button-container .jw-icon-rewind,
.preview-video .jw-button-container .jw-text-elapsed,
.preview-video .jw-button-container .jw-text-duration,
.preview-video .jw-button-container .jw-settings-sharing,
.preview-video .jw-button-container .jw-settings-submenu-button,
.preview-video .jw-button-container .jw-settings-pip,
.preview-video .jw-button-container .jw-icon-pip,
.preview-video .jw-button-container .jw-icon-fullscreen,
.preview-video .jw-icon .jw-icon-rewind .jw-button-color .jw-reset,
.preview-video .jw-icon.jw-icon-rewind.jw-button-color.jw-reset {
  display: none;
}

/** Styling for atomic video **/
.atomic-video {
  height: 100%;
}

.atomic-video > div {
  height: 100%;
}

.atomic-video .jwplayer.jw-flag-aspect-mode {
  height: 100% !important;
}

.atomic-video .jwplayer.js-flag-aspect-mode .jw-aspect {
  padding: 0;
}

/* force override default rewind on preview video  */
.preview-video .jw-display {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.preview-video .jwplayer.jw-state-playing.jw-flag-user-inactive .jw-display {
  display: block;
}

.preview-video .jw-controlbar {
  visibility: visible !important;
  opacity: 1 !important;
}

.preview-video .jw-icon.jw-icon-display.jw-button-color.jw-reset {
  position: absolute;
  right: 70px;
  bottom: 30px;
  height: auto;
  line-height: 1;
}

.preview-video .jw-icon.jw-icon-volume {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

/* this repositions the play/pause and mute/volume buttons on mobile */
@media (max-width: 1024px) {
  .preview-video .jw-icon.jw-icon-display.jw-button-color.jw-reset {
    right: 50px;
    bottom: 10px;
  }

  .preview-video .jw-icon.jw-icon-volume.jw-button-color.jw-reset {
    right: 10px;
    bottom: 10px;
  }
}

.preview-video .jw-svg-icon,
.preview-video .jw-icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 1 !important;
}

/* brings the video player play button above the overlay */
.jw-display {
  z-index: 1;
}

/* removes outline that displays on hover */
.preview-video .jw-icon-volume::after {
  box-shadow: none;
}

/* updated homepage hero, like watch preview vids, just full-height not 85vh */

.desktop-video-hero-wrapper {
  height: calc(
    100vh - var(--outside-header-height) - var(--site-header-height)
  );
  min-height: calc(
    100vh - var(--outside-header-height) - var(--site-header-height)
  );
}

.desktop-video-hero {
  height: 100%;
}

.desktop-video-hero > div {
  width: 100%;
  height: 100%;
}

.desktop-video-hero img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

/* COURSE COMPLETION */
.is-complete {
  @apply relative flex items-center text-network-storm;
}

.is-complete:before {
  content: url(/checkmark.svg);
  @apply absolute;
  left: -22px;
}

.cmp-body *,
.cc-window.cc-banner * {
  @apply font-plus-jakarta-sans;
}

.cmp-body ul,
.cmp-body ul li {
  @apply list-none;
}

@layer utilities {
  /* Works on Firefox */
  .custom-scrollbar {
    scrollbar-width: 8px;
    scrollbar-color: #4e4e4e transparent;
    overflow-y: scroll;
  }

  /* Works on Chrome, Edge, and Safari */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    overflow-y: scroll;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 8px;
  }
}

/*
Styles for priority-plus package
 */

.pp-overflow-wrapper > li {
  @apply text-18;
}

.pp-overflow-wrapper > li > button {
  @apply w-full p-4 text-left text-12 transition-colors duration-300 hover:bg-foreground-default hover:text-accent-secondary-hover focus-visible:bg-foreground-default focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus sm:text-18;
}

.pp-toggle-button {
  @apply px-1.5 py-0.5 md:px-3 md:py-2;
}

/*
Derived from https://github.com/pocketoutdoormedia/outside-auth-header/blob/main/styles/globals.css#L51-L53
 */
@layer components {
  .loading-spinner {
    @apply h-[60px] w-[60px] animate-spin rounded-full border-[7px] border-network-outside-yellow border-l-transparent;
  }

  /*
     Important flag was used because  bitdev packages globally overwrite styles.
     */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a,
  li,
  ul,
  ol,
  li {
    @apply font-plus-jakarta-sans !important;
  }
}

/*
Isolate z-index to main root node
 */
#__next {
  isolation: isolate;
}

/* global header elevation */
out-header {
  z-index: 21;
}

/* header Scout App link */

#scout-star {
  transform-origin: center;
  animation: star-animation 5s forwards;
}

@keyframes star-animation {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.25);
  }
}
